import {
    VuexModule,
    Module,
    getModule,
    config,
    Mutation,
    Action,
} from "vuex-module-decorators";
import { User, UserCredentials } from "./types";
import store from "@/store/";
import axios, { APIResponse } from "@/plugins/axios";
import router from "@/router";
import { updateAbilities } from "@/plugins/casl/abilities";

// Set rawError to true for all @Action
config.rawError = true;

/**
 * VuexModule for user authentication
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: "auth", dynamic: true })
class AuthModule<UserType extends User> extends VuexModule {
    // Property of type User (can be null)
    public user: UserType | null = null;

    /**
     * Assigns the given value to state.user and set token in localStorage
     *
     * @param user user object
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setUser(user: UserType) {
        this.user = user;
        localStorage.setItem("token", user.token);
    }

    /**
     * Assigns the null to state.user and set token in localStorage
     *
     * @author Kevin Danne <danne@skiba-proocmputer.de>
     */
    @Mutation
    private resetUser() {
        this.user = null;
        localStorage.removeItem("token");
    }

    /**
     * Redirects the user to nextUrl if set, otherwise the user is redirected to LOGIN_REDIRECT_URLNAME set in the .env file
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    private async redirectUser() {
        if (router.currentRoute.query.nextUrl) {
            router.push(router.currentRoute.query.nextUrl as string);
        } else {
            router.push({ name: process.env.VUE_APP_LOGIN_REDIRECT_URLNAME });
        }
    }

    /**
     * Calls setUser, sets the Authorization header, update abilities
     *
     * @param user user object
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async setAuthorizationHeaderAndUser(user: UserType) {
        this.setUser(user);
        axios.defaults.headers.common["Authorization"] = "Token " + user.token;

        if (this.user != null && this.user.permissions != null) {
            updateAbilities(
                this.user.permissions.map((permission) => permission.name)
            );
        }
    }

    /**
     * Checks the UserCredentials and logs the user on
     *
     * @param userCredentials UserCredentials object
     *
     * @returns promise with response data
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async login<T = UserCredentials>(userCredentials: T): Promise<void> {
        const response = await axios.post<APIResponse<UserType>>(
            "/user/login",
            userCredentials
        );
        if (response.data.status === "error") {
            throw new Error(response.data.message || "unknownError");
        }

        this.setAuthorizationHeaderAndUser(response.data.data);
        this.redirectUser();
    }

    /**
     * Checks if a token is set in the localStorage and if it is still valid
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async tryAutoLogin() {
        const token = localStorage.getItem("token");
        if (!token) return;

        try {
            const response = await axios.post<APIResponse<UserType>>(
                "/user/checktoken",
                { token: token }
            );
            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }

            this.setAuthorizationHeaderAndUser(response.data.data);
            if (router.currentRoute.query.nextUrl) {
                this.redirectUser();
            }
        } catch (err) {
            localStorage.removeItem("token");
            throw err;
        }
    }

    /**
     * Logs the user out
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async logout() {
        delete axios.defaults.headers.common["Authorization"];
        this.resetUser();
        updateAbilities([]);

        router.push({ name: "login" });
    }

    /**
     * @returns a boolean that represents whether the user is logged in
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get isAuthenticated(): boolean {
        return this.user != null;
    }
}

// Export AuthModule
export default getModule(AuthModule);

// export types
export * from "./types";
