import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import "./plugins/casl/vue";
import AuthModule from "./store/modules/auth";

import Vuetify from "vuetify/lib";
import { de, en, nl, fr } from "vuetify/src/locale";
Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang: {
    locales: { de, en, nl, fr },
    current: i18n.locale
  },
  theme: {
    themes: {
      light: {
        primary: '#221e20',
        secondary: '#4e4e4e',
        danger: '#C90C0F'
      },
      dark: {
        primary: '#221e20',
        secondary: '#4e4e4e',
        danger: '#C90C0F'
      }
    }
  }
});

Vue.config.productionTip = false;

// Try login with token form localStorage
AuthModule.tryAutoLogin().finally(() => {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
