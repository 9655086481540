











import { Component, Prop, Vue } from "vue-property-decorator";
import ConfiguratorConfigurationSelectionListItem from "./ConfiguratorConfigurationItemSelectionListItem.vue";
import { ConfiguratorConfigurationSelectionItem } from "@/types/configurator";

/**
 * ConfiguratorConfigurationSelectionList component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ConfiguratorConfigurationSelectionListItem,
    },
})
export default class ConfiguratorConfigurationItemSelectionList extends Vue {
    @Prop({ type: Array, required: true })
    readonly items!: ConfiguratorConfigurationSelectionItem[];
}
