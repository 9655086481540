


















































import { Component, Prop, Vue } from "vue-property-decorator";
import ConfiguratorConfigurationItemSelectionList from "@/components/configurator/configuration/step/item-selection/ConfiguratorConfigurationItemSelectionList.vue";
import { ArticleProperty } from "@/types/shop/article";
import {
    ConfiguratorArticleProperty,
    ConfiguratorConfigurationSelectionItem,
} from "@/types/configurator";

/**
 * ConfiguratorConfigurationStepByProperty component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ConfiguratorConfigurationItemSelectionList,
    },
})
export default class ConfiguratorConfigurationStepByProperty extends Vue {
    @Prop({ type: Array, required: true })
    readonly properties!: ArticleProperty[];

    @Prop({ type: Object, required: true })
    readonly value!: ArticleProperty;

    private tempValue = this.value;

    private formValid = false;

    private get unitSelectionListItems(): ConfiguratorConfigurationSelectionItem[] {
        return this.properties
            .filter((prop) => prop.unit !== null)
            .map((prop) => ({
                id: prop.unit!.id,
                name: prop.unit!.names[0]?.name || "N/A",
                image: prop.unit!.image,
                cbData: prop,
            }));
    }

    private async handlePropertySelection(
        id: number,
        item: ConfiguratorArticleProperty
    ) {
        this.tempValue = { ...item };
        this.$emit("input", { ...item });
    }

    /**
     * Function to change property value
     * Emits input event
     *
     * @param value value
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private handlePropertyValueChange(value: string) {
        const propValue = this.value.value != null ? {
            ...this.value.value,
            value: parseFloat(value),
        } : null;

        this.tempValue = {
            ...this.value,
            value: propValue
        };
        this.$emit("input", {
            ...this.value,
            value: propValue
        });
    }

    /**
     * Function to validate property value
     *
     * @returns boolean | string
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private validatePropertyValue(): boolean | string {
        // return true if value is not required
        if (this.value.value === null) return true;

        // validate minValue
        if (
            this.value.value.minValue !== null &&
            this.value.value.value < this.value.value.minValue
        ) {
            return this.$t("form.rules.minValue", {
                minValue: this.value.value.minValue,
            }).toString();
        }

        // validate maxValue
        if (
            this.value.value.maxValue !== null &&
            this.value.value.value > this.value.value.maxValue
        ) {
            return this.$t("form.rules.maxValue", {
                maxValue: this.value.value.maxValue,
            }).toString();
        }

        return true;
    }

    /**
     * Function validates form
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private validateForm() {
        // @ts-ignore
        if (typeof this.$refs?.form?.validate === "function") {
            (
                this.$refs.form as Vue & {
                    validate: () => void;
                }
            ).validate();
        }
    }
}
