
























import { Component, Vue } from "vue-property-decorator";
import AppNavbar from "@/components/Navbar.vue";
/*import AppMenubar from "@/components/Menubar.vue";*/
import AppFooter from "@/components/Footer.vue";
import AppAlert from "@/components/Alert.vue";

import { ArticleProperty } from "@/types/shop/article";
import ConfiguratorConfigurationStepByProperty from "@/components/configurator/configuration/step/property/ConfiguratorConfigurationStepByProperty.vue";

/**
 * App view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AppNavbar,
        /*AppMenubar,*/
        AppFooter,
        AppAlert,
        ConfiguratorConfigurationStepByProperty,
    },
})
export default class App extends Vue {
    private properties: ArticleProperty[] = [
        {
            id: 0,
            names: [
                {
                    id: 0,
                    langcode: "de",
                    name: "Farbe",
                },
            ],
            unit: {
                id: 0,
                names: [
                    {
                        id: 0,
                        langcode: "de",
                        name: "Nickel Matt",
                    },
                ],
                idAdditive: "",
                image: {
                    id: 0,
                    alt: "Nickel matt",
                    src: "http://app.buesche.de/assets/images/colors/29.png",
                },
            },
            value: {
                id: 0,
                maxValue: null,
                minValue: null,
                value: 10.5,
            },
        },
        {
            id: 1,
            names: [
                {
                    id: 0,
                    langcode: "de",
                    name: "Farbe",
                },
            ],
            unit: {
                id: 1,
                names: [
                    {
                        id: 0,
                        langcode: "de",
                        name: "Messing Glanz",
                    },
                ],
                idAdditive: "",
                image: {
                    id: 0,
                    alt: "Nickel matt",
                    src: "http://app.buesche.de/assets/images/colors/24.png",
                },
            },
            value: null,
        },
        {
            id: 2,
            names: [
                {
                    id: 0,
                    langcode: "de",
                    name: "Farbe",
                },
            ],
            unit: {
                id: 2,
                names: [
                    {
                        id: 0,
                        langcode: "de",
                        name: "Messing Matt",
                    },
                ],
                idAdditive: "",
                image: {
                    id: 0,
                    alt: "Messing glatt",
                    src: "http://app.buesche.de/assets/images/colors/24.png",
                },
            },
            value: null,
        },
    ];

    private property = this.properties[0];
}
