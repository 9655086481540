



























import { Component, Prop, Vue } from "vue-property-decorator";
import { ConfiguratorConfigurationSelectionItem } from "@/types/configurator";

/**
 * ConfigurationSelectionListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class ConfigurationConfigurationSelectionListItem extends Vue {
    @Prop({ type: Object, required: true })
    readonly item!: ConfiguratorConfigurationSelectionItem;
}
