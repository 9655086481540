






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
    private socialLinks = [
        {
            icon: "mdi-instagram",
            href: "https://www.instagram.com/h.buesche/",
        },
        {
            icon: "mdi-facebook",
            href: "https://www.facebook.com/HBuescheGmbH/",
        },
        {
            icon: "mdi-email",
            href: "mailto:mail@buesche.de",
        },
    ];
}
