var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","light":"","backgroundColor":"white","color":"primary","placeholder":_vm.$t('configurator.search.placeholder')},on:{"input":_vm.searchConfigurations}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[_c('v-list-item',[_c('v-expansion-panels',{attrs:{"multiple":"","flat":""},model:{value:(_vm.expansionPanelValue),callback:function ($$v) {_vm.expansionPanelValue=$$v},expression:"expansionPanelValue"}},_vm._l((Object.keys(
                        _vm.configurationSearchResults
                    )),function(searchResultCategory){return _c('v-expansion-panel',{key:searchResultCategory},[(
                            searchResultCategory === 'configurationResults'
                        )?_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t( "configurator.configuredConfiguration.search.resultCategory." + searchResultCategory ))+" ")]):_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t( "shop.article.search.resultCategory." + searchResultCategory ))+" ")]),_c('v-expansion-panel-content',_vm._l((_vm.configurationSearchResults[
                                searchResultCategory
                            ]),function(searchResultItem){return _c('p',{key:(searchResultCategory + "-" + (searchResultItem.id))},[(
                                    searchResultCategory ==
                                    'configurationResults'
                                )?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.redirectToConfiguredConfiguration(
                                        searchResultItem.id
                                    )}}},[_vm._v(" "+_vm._s(searchResultItem.number)+" ")]):_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.redirectToArticleDetails(
                                        searchResultItem.id
                                    )}}},[_vm._v(" "+_vm._s(searchResultItem.description.title)+" ")])],1)}),0)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }